import {useNavigate} from 'react-router-dom';
import {useContext } from 'react';
import { AppContext } from '../../App';
import './BlogCardPreview..css';
const BlogCardPreview= ({blog}) => {
  const navigate = useNavigate();
  const {FormatDate} = useContext(AppContext);
  const CardDetails = ()=>{
    navigate(`Details/${blog.Id}`);
  }
  return (
 
    <div className="blog-card card-in-list">
    <div className="blog-header blog-header-list"> 
      <div className="image-list-container">
       <img src ={`https://itqanhub.com/BlogsImages/${blog.PngName}.png`} className="blog-image-list"alt="Blog" loading="lazy"/>
      </div>
    <h5 className="blog-date blog-date-list"> {FormatDate(blog.BlogDate)}</h5>
    </div>
    <div className="blog-body blog-body-list">
    <p className="blog-title-list">
      {blog.Title}
      </p>
     
      <div className="button-container">
       <button onClick={CardDetails} className="read-more-button">
       Read More
       </button>
      </div>
    </div>
    </div>
  
  )
}

export default BlogCardPreview
