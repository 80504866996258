import { lazy, Suspense, createContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga4';
import './App.css';
import Loader from './Components/Loader/Loader'; 
import NotFoundImg from './Images/404SVG.svg';
import BlogPage from './Components/BlogPage/BlogPage';
import HomePage from './Components/HomePage/HomePage';
import SchedulePage from './Components/SchedulePage/SchedulePage';
const Header = lazy(() => import('./Components/Header/Header'));
const Footer = lazy(() => import('./Components/Footer/Footer'));
const AboutUsPage = lazy(() => import('./Components/AboutUsPage/AboutUsPage'));
const OurServicesPage = lazy(() => import('./Components/OurServicesPage/OurServicesPage'));
const OurPartnersPage = lazy(() => import('./Components/OurPartnersPage/OurPartnersPage'));
const JobsPage = lazy(() => import('./Components/JobsPage/JobsPage'));
const BlogCardDetails = lazy(() => import('./Components/BlogCardDetails/BlogCardDetails'));
const TrainingForm = lazy(() => import('./Components/TrainingForm/TrainingForm'));
const JobDetails = lazy(() => import('./Components/JobDetails/JobDetails'));
const Unsubscribe = lazy(() => import('./Components/Unsubscribe/Unsubscribe'));
const Placeholder = lazy(() => import('./Components/Placeholder/Placeholder'));

export const AppContext = createContext();

// Google Analytics
const MEASURING_ID = process.env.REACT_APP_Google_Analytics || "G-T40Z98FS64";
ReactGA.initialize(MEASURING_ID);

function App() {
  function FormatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }

  return (
    <HelmetProvider>
      <AppContext.Provider value={{ FormatDate }}>
        <Suspense fallback={<Loader />}>
          <div className="App">
            <Helmet>
              <link rel="alternate" hreflang="en" href="https://itqanhub.com/" />
            </Helmet>
            <Header />
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/AboutUs' element={<AboutUsPage />} />
              <Route path='/OurServices' element={<OurServicesPage />} />
              <Route path='/OurPartners' element={<OurPartnersPage />} />
              <Route path='/Schedule' element={<SchedulePage />} />
              <Route path='/Jobs' element={<JobsPage />} />
              <Route path='/Training/:Course/:Day/:Month' element={<TrainingForm />} />
              <Route path='/JobDetails' element={<JobDetails />} />
              <Route path='/Blog' element={<BlogPage />} />
              <Route path='/Blog/Details/:id' element={<BlogCardDetails />} />
              <Route path='/Email/Unsubscribe' element={<Unsubscribe />} />
              <Route path='/*' element={<Placeholder img={NotFoundImg} text="Page Not Found" buttonRoute="/" buttonText="Back To Home" />} />
            </Routes>
            <Footer className="FooterComponent" />
          </div>
        </Suspense>
      </AppContext.Provider>
    </HelmetProvider>
  );
}

export default App;
