import { useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader';
import ReactGA from 'react-ga4';
import './TrainingForm.css';
const TrainingForm = ({courseName,courseDate,showSuccessMessage,setShowTrainingForm}) => {
    const [isLoading,setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      jobTitle: '',
      mobile: '',
      trainingName:courseName,
      trainingVersion:courseDate,
      country: '',
      numberOfSeats: '',
      company: '',
      message: '',
      formType:'training'
    });
    const handleInputChange = (e) =>{
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    const TrainingFormHandler=async(event)=>{
    event.preventDefault();
    if(!event.target.name.value || !event.target.jobTitle.value || !event.target.email.value || !event.target.mobile.value || !event.target.trainingName.value
     || !event.target.trainingVersion.value || !event.target.country.value || !event.target.numberOfSeats.value || !event.target.company.value )
    {
      toast.warn("Please Fill Required Fields");
    }
    else if (event.target.numberOfSeats.value<=0){
      toast.warn("Please Enter A Valid Number Of Seats");

    }
    else{
      setIsLoading(true);
      try {
        setFormData((prevData) => ({
          ...prevData,
          trainingName:event.target.trainingName.value,
          trainingVersion:event.target.trainingVersion.value
        }));
        const response = await fetch('https://itqanhub.com/Apis/Training.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
    
        if (!response.ok) {
          toast.error(response.error);
        }
        else{
        await response.json();
        setIsLoading(false);
        toast.success("Thanks for reaching out! We've received your message");
        fetch('https://proditqan.vercel.app/api/send-mail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
    
        setFormData({
          name: '',
          email: '',
          jobTitle: '',
          mobile: '',
          trainingName:courseName,
          trainingVersion:courseDate,
          country: '',
          numberOfSeats: '',
          company: '',
          message: ''
        });
        
        }
          } catch (error) {
         setIsLoading(false);
         toast.warn("We Couldn't Receive Your Message Please Try Again Later");
              }
            }
      ReactGA.event({
        category: 'Form',
        action: 'Form Submission',
        label: 'Training Form' 
      });
    }
  return (
    <>
         <div className="PageContainer">
         <ToastContainer />
{isLoading?<Loader/>:<form  className="ContactForm" method="post" id="TrainingForm" onSubmit={TrainingFormHandler}>
<label  htmlFor="trainingName">Training<br/>
    <input type="text" name="trainingName" id="trainingName" value={courseName} readOnly/>
</label>
<label  htmlFor="trainingVersion">Training Start Date <br/>
    <input type="text" name="trainingVersion" id="trainingVersion" value={courseDate} readOnly/>
</label>
<label htmlFor="name" >Full Name <span id="NameAsterik">*</span><br/>
    <input type="Text" name="name" id="name" placeholder="Your Name Goes Here..."  value={formData.name} onChange={handleInputChange} autoComplete='Name'/>
</label>
<label  htmlFor="jobTitle">Job Title <span id="TitleAsterik">*</span><br/>
    <input type="text" name="jobTitle" id="jobTitle" placeholder="Your Job Title Goes Here..." value={formData.jobTitle} onChange={handleInputChange}/>
</label>
<label  htmlFor="email">Email <span id="EmailAsterik">*</span><br/>
    <input type="email" name="email" id="Email"placeholder="Your Email Goes Here..."  onChange={handleInputChange} value={formData.email} autoComplete='Email'/>
</label>
<label  htmlFor="mobile">Mobile Number <span id="MobileNumberAsterik">*</span><br/>
    <input type="text" name="mobile" id="mobile" value={formData.mobileN}  onChange={handleInputChange} placeholder="Your Mobile Number Goes Here..." />
</label>
<label  htmlFor="country">Country <span id="CountryAsterik">*</span><br/>
    <input type="text" name="country" id="country" placeholder="Your Country Goes Here..." value={formData.country} onChange={handleInputChange}  autoComplete='Country'/>
</label>
<label  htmlFor="company">Company <span id="CompanyAsterik">*</span><br/>
    <input type="text" name="company" id="company" placeholder="Your Company Goes Here..."  value={formData.company} onChange={handleInputChange} autoComplete='Company'/>
</label>
<label  htmlFor="numberOfSeats">Number Of Seats <span id="SeatsAsterik">*</span><br/>
    <input type="number" name="numberOfSeats" id="numberOfSeats" placeholder="No. Of Seats Goes Here..."value={formData.numberOfSeats} onChange={handleInputChange} />
</label>
<label htmlFor="message">Message<br/>
    <textarea name="message" id="message" placeholder="Your text Goes Here..."  value={formData.message} onChange={handleInputChange}></textarea>  
</label>

<button type="submit" className="Button" id="SubmitButton">Send</button>
</form>}
</div>
    </>
  )
}


export default TrainingForm
