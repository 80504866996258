import ReactGA from 'react-ga4';
import './CourseCard.css';
const CourseCard = ({course,TrainingFormSetter}) => {
  function FormatDate(date){
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const courseImg = `https://itqanhub.com/CoursesImages/${course.PngName}.png`;
  const coursePdf = `https://itqanhub.com/CoursesPDFs/${course.PDFName}.pdf`;
  const startDateTime = new Date(course.StartDate);
  const endDateTime = new Date(course.EndDate);
  const startDay = startDateTime.getDate();
  const formattedStartDate = FormatDate(startDateTime);
  const now = new Date();
  const formattedTimeNow =FormatDate(now);
  const endDay = endDateTime.getDate();
  const startMonth = startDateTime.toLocaleString('default', { month: 'short' });
  const endMonth = endDateTime.toLocaleString('default', { month: 'short' });
  const parseTime = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return { hours, minutes };
  };
  const startTime = parseTime(course.StartTime);
  const endTime = parseTime(course.EndTime);

  let startHour = startTime.hours;
  let startMinutes = startTime.minutes;
  let endHour = endTime.hours;
  let endMinutes = endTime.minutes;
  const sPmorAm = startHour >= 12 ? 'PM' : 'AM';
  const ePmorAm = endHour >= 12 ? 'PM' : 'AM';

  startHour = startHour % 12 || 12;
  endHour = endHour % 12 || 12;
  const enrollButtonClicked = (courseName,courseDate)=>{
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Training Enroll Button' 
    });
    TrainingFormSetter(courseName,courseDate);
  }
  return (
    <div className="card2 card1">
    <div className="CalendarLogos">
      <img
        src={courseImg}
        alt={`${course.PngName} Logo`} loading="lazy"
      />
    </div>
    <h3 className="CourseTitle">{course.CourseName}</h3>
    <hr className="ServicesPreviewLine CardLine" />
    <div className="CourseDetails">
      <div className="CalendarDate blue-text">
        <div className="CalendarHeader blue-bg">
          <h4 className="CalendarMonth light-text">
            {startMonth === endMonth
              ? startMonth.toUpperCase()
              : `${startMonth.toUpperCase()}-${endMonth.toUpperCase()}`}
          </h4>
        </div>
        <div className="CalendarBody">
          <h3 className="DateText">
            {startDateTime.toDateString() === endDateTime.toDateString()
              ? startDay
              : `${startDay} - ${endDay}`}
          </h3>
        </div>
      </div>
      <div className="TimeandPlace">
        <h4 className="Time">
          {startHour}:{startMinutes.toString().padStart(2, '0')} {sPmorAm} - {endHour}:{endMinutes.toString().padStart(2, '0')} {ePmorAm}
        </h4>
        <h4 className="Time">{course.Place}</h4>
      </div>
    </div>
    {course.PDFName && <a href={coursePdf} className="DownloadPDF blue-text" target="_blank" rel="noopener noreferrer">
      Training Description
    </a>}
      {(formattedStartDate > formattedTimeNow)? 
      (<button className="Button CalendarButton" onClick={()=>enrollButtonClicked(course.CourseName,startDay+" " +startMonth)}>Enroll</button>)
      :(<div>
         <p className="Time">Registration is Closed <br/> Stay Tuned For More</p>
      </div>)}
  </div>
  )
}

export default CourseCard
